.contact-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
}

.contact-header {
  width: 50%;
  margin: 10rem 10px;
  text-align: center;
}

h1 {
  font-family: "AlienEncounters";
  font-size: 3.3rem;
  line-height: 1;
}

.contact-header p {
  font-family: "RalewayBold";
  font-size: 1.2rem;
  margin: 10px 40px;
}

.contact-form {
  width: 50%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 8rem;
}

.contact-form h3 {
  font-size: 2rem;
  font-family: "RalewayBold";
}

input {
  color: #b3b5b0;
  font-size: 1rem;
  font-family: "RalewayBold";
  border: 2px solid #a3a5a0;
  width: 93%;
  height: 25px;
  padding: 10px;
  border-radius: 3px;
}

textarea {
  color: #b3b5b0;
  font-size: 1rem;
  font-family: "RalewayBold";
  border: 2px solid #a3a5a0;
  width: 93%;
  height: 120px;
  padding: 10px;
  border-radius: 3px;
}

input:focus,
textarea:focus {
  outline: none; /* Change only the color on focus */
}

label {
  display: none;
}

.contact-form button {
  font-size: 1.5rem;
  background: #c4061b;
  border-radius: 10px;
  border: none;
  padding: 10px 50px;
  text-transform: uppercase;
  color: white;
  font-family: "RalewayBold";
}

.contact-form button:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 950px) {
  .contact-header {
    width: 95%;
  }
}

@media (max-width: 700px) {
  .contact-form {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .contact-header h1 {
    font-size: 2.5rem;
  }
  .contact-header {
    margin: 5rem 10px;
  }

  .contact-header p {
    font-size: 1rem;
  }
}
