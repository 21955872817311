.hero-call-to-action {
  width: 100%;
  position: relative;
  background-size: cover; /* Ensures the image covers the full section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  height: 50vh; /* Adjust height based on design */
  display: flex;
  justify-content: center;
  align-items: end;
  color: #fff;
  padding-bottom: 5%; /* Ensures text is readable */
}

.hero-call-to-action::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Black overlay with opacity */
  z-index: 1; /* Places the overlay behind content */
}

.hero-content {
  width: 90%;
  position: relative;
  z-index: 2; /* Ensures content is above the overlay */
  text-align: center;
  max-width: 800px;
}

.hero-content h2 {
  width: 100%;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "RalewayBold"; /* Ensure font matches your branding */
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-family: "RalewayBold";
}

.cta-button {
  font-size: 1rem;
  font-family: "RalewayBold";
  text-transform: uppercase;
  background-color: #c4061b;
  color: white;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #a10418;
}
