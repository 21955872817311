.event-highlights {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; /* Space between cards */
  padding: 2rem; /* Light background for contrast */
  margin-bottom: 8rem;
}

.highlight-card {
  width: 80%;
  display: flex;
  gap: 3rem;
  align-items: center;
  background-color: #eaeaea;
  padding: 1.5rem;
}

.highlight-image {
  width: 45%; /* Adjust to your design */
  object-fit: cover;
  height: 240px;
}

.highlight-text {
  width: 40%;
  text-align: left;
}

.highlight-text h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.highlight-text p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 850px) {
  .highlight-card {
    width: 95%;
  }
}

@media (max-width: 650px) {
  .highlight-card {
    flex-direction: column;
    gap: 1rem;
  }
  .highlight-card img {
    width: 100%;
  }

  .highlight-text {
    width: 100%;
  }
}
