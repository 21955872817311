.testimonials {
  width: 90%;
  margin: 8rem 2%;
  background-color: #eaeaea;
  border-top: 10px solid #c4061b;
  display: flex;
  justify-content: center;
}

.test-interior {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quote-container {
  display: flex;
  flex-direction: row;
}

.quote {
  width: 100%;
  max-width: 900px;
  padding: 4rem 3%;
}

.quote h3 {
  font-size: 1.3rem;
  font-family: "RalewayBold";
}

.quote p {
  margin-top: 15px;
  font-family: "Raleway";
  font-weight: 100;
}

/* New styles for slideshow controls */

.arrow {
  color: #c3c4c2;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .test-interior {
    width: 95%;
  }
}

@media (max-width: 700px) {
  .quote h3 {
    font-size: 1rem;
  }

  .arrow {
    display: none;
  }
}
