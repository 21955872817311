*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Font Faces */
@font-face {
  font-family: "AlienEncounters";
  src: url("./fonts/Alien-Encounters-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AlienEncountersBold";
  src: url("./fonts/Alien-Encounters-Solid-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RalewayBold";
  src: url("./fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Apply Default Font Styling */
body {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased; /* Smoother text rendering */
  -moz-osx-font-smoothing: grayscale; /* Smoother text rendering */
  line-height: 1.6; /* Better readability */
  margin: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.main-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button:hover {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

a {
  text-decoration: none; /* Remove underlines */
  color: inherit; /* Use current text color */
  cursor: pointer; /* Ensure links look clickable */
}

button {
  color: inherit;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
