.about-us {
  width: 100%;
}

.about-head {
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 40vh;
  color: #fff;
  text-align: center;
  width: 50%;
  color: black;
  max-width: 800px;
  margin-bottom: 20px;
}

.about-head h1 {
  font-size: 5rem;
}

.about-cowboy {
  max-width: 900px;
  margin: 0 10%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.8rem;
}

.about-cowboy img {
  width: 45%;
  height: 45vh;
  object-fit: cover;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
}

.right-side h2 {
  font-family: "RalewayBold";
  margin-bottom: 20px;
  width: 100%;
}

.bottom {
  width: 100%;
}

.bottom h3 {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-family: "RalewayBold";
  padding-bottom: 1rem;
}
.about-bottom {
  width: 100%;
  margin-top: 8rem;
  background-image: url("../media/widecolor.jpg"); /* Fixed background-image syntax */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: top; /* Centers the image */
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10%;
  color: white;
  flex-direction: column;
}

.bottom-container {
  width: 95%;
  max-width: 1100px;
}

.about-bottom h2 {
  width: 100%;
  font-family: "RalewayBold";
  color: white;
  font-size: 1.5rem;
  max-width: 1100px;
}

@media (min-width: 1100px) {
  .about-bottom {
    background-position: center;
  }
}

@media (max-width: 1100px) {
  .about-head {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .about-cowboy {
    text-align: center;
  }
  .about-cowboy img {
    width: 100%;
    height: 80vh;
    object-position: center;
  }

  .right-side {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .about-head h1 {
    font-size: 4rem;
  }

  .about-head p {
    font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .about-bottom h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .about-cowboy img {
    height: 60vh;
  }

  .about-head {
    height: fit-content;
  }
}
