.contact-form-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin: 8rem 10px;
  max-width: 1100px;
  gap: 6rem;
}

.form-text {
  width: 70%;
}

.form-text h2 {
  font-weight: bold;
  width: 100%;
  font-size: 2.4rem;
}

.form-text p {
  font-family: "RalewayBold";
}

.contact-form-cta {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  margin-bottom: 8rem;
}

.align {
  width: 100%;
  max-width: 900px;
  text-align: center;
}

.contact-form-cta button {
  font-size: 1.5rem;
  background: #c4061b;
  border-radius: 10px;
  border: none;
  padding: 10px 50px;
  text-transform: uppercase;
  color: white;
  font-family: "RalewayBold";
}

.contact-form-cta button:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 950px) {
  .contact-form-container {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .form-text {
    width: 90%;
    text-align: center;
  }
}

@media (max-width: 450px) {
}
